import { AreaModel } from 'api-hooks/product';
import classNames from 'classnames';
import {
  CustomizationUnit,
  SmartCustomizationUnit,
  applyCustomization,
} from 'common/repositories/customization';
import VirtualizedComponent from 'components/virtualized-component';
import {
  CountryCard,
  CountryListItem,
  RegionListItem,
} from 'modules/main/components/area-card';
import React from 'react';
import structuralStyles from 'styles/layout.css';

import { AreaListStyles } from './styles.css';

// Not to be used in other models. This is specific to Area List
interface CountryListProps {
  countries: AreaModel[];
  customization?: {
    root?: CustomizationUnit<React.ComponentProps<'ul'>>;
    item?: React.ComponentProps<typeof CountryListItem>['customization'];
    before?: React.ReactNode;
    after?: React.ReactNode;
  };
}

interface RegionListProps {
  regions: AreaModel[];
  customization?: {
    root?: CustomizationUnit<React.ComponentProps<'ul'>>;
    item?: SmartCustomizationUnit<AreaModel, React.ComponentProps<'div'>>;
    before?: React.ReactNode;
    after?: React.ReactNode;
  };
}

const maxWidth = 768;

export function CountryList(props: CountryListProps) {
  const { countries, customization } = props;
  return (
    <ul
      {...applyCustomization({}, [customization?.root])}
      style={{
        height: 51 * countries.length,
      }}
    >
      {customization?.before}
      {countries.map((country) => {
        return (
          <VirtualizedComponent
            maxHeight={51}
            maxWidth={maxWidth}
            key={country.id}
            threshold={0.5}
          >
            <CountryListItem
              key={country.id}
              country={country}
              customization={customization?.item}
            />
          </VirtualizedComponent>
        );
      })}

      {customization?.after}
    </ul>
  );
}

export function RegionList(props: RegionListProps) {
  const { regions, customization } = props;

  return (
    <ul
      {...applyCustomization(
        {
          className: classNames(
            structuralStyles.flexbox({
              direction: 'column',
              align: 'start',
            }),
            structuralStyles.overflow({ x: 'auto' }),
            structuralStyles.padding({
              vertical: 8,
              horizontal: 0,
            }),
            structuralStyles.fill({ width: true }),
            AreaListStyles.noScrollbar,
          ),
        },
        [customization?.root],
      )}
    >
      {customization?.before}
      {regions.map((region) => {
        return (
          <VirtualizedComponent
            key={region.id}
            maxHeight={64}
            maxWidth={maxWidth}
            threshold={0.5}
          >
            <RegionListItem region={region} key={region.id} />
          </VirtualizedComponent>
        );
      })}
      {customization?.after}
    </ul>
  );
}

export const CountryCardList = React.forwardRef<
  HTMLUListElement,
  CountryListProps
>((props, ref) => {
  const gapLenght = 8 * props.countries.length - 1;
  const totalWidth = 135 * props.countries.length + gapLenght;
  return (
    <ul
      {...applyCustomization(
        {
          className: classNames(
            structuralStyles.flexbox({ gap: 8 }),
            structuralStyles.padding({ vertical: 8 }),
            structuralStyles.overflow({ x: 'auto' }),
          ),
          style: {
            minWidth: totalWidth,
          },
        },
        [props.customization?.root],
      )}
    >
      {props?.customization?.before}
      {props.countries.map((country) => {
        return (
          <VirtualizedComponent
            maxHeight={90}
            maxWidth={135}
            threshold={0}
            key={country.id}
          >
            <CountryCard
              key={country.id}
              country={country}
              customization={props.customization?.item}
            />
          </VirtualizedComponent>
        );
      })}
      {props?.customization?.after}
    </ul>
  );
});
